import React, { createRef, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import eclLogoLight from "images/ecl-logo-light.png";
import html2canvas from "html2canvas";
import pageImage from "images/HomepageLogo.png";
import styled, { ThemeProvider } from "styled-components";
import { Col, Row } from "reactstrap";
import {
  GetStartedPageContainer,
  GetStartedPricingTable,
  LinkToOpenInNewWindow,
  SEO,
} from "components";
import { jsPDF } from "jspdf";
import { navigate } from "gatsby";
import { pricingLookupTableData, routes, theme } from "utils";

const GetStartedQuotePage = ({ location }) => {
  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  // Check if quote has basic information to display. If not, that means
  // the user navigated to this url directly, so we would like to redirect them
  // back to the Get Started page
  useEffect(() => {
    // if (
    //   !urlParams?.get("fullName") ||
    //   !urlParams?.get("companyName") ||
    //   !urlParams?.get("address") ||
    //   !urlParams?.get("submitDate") ||
    //   !urlParams?.get("quoteNumber") ||
    //   !urlParams?.get("quoteExpirationDate") ||
    //   !urlParams?.get("desiredStartDate") ||
    //   !urlParams?.get("accountType") ||
    //   !urlParams?.get("selectedPlan") ||
    //   !urlParams?.get("data")
    // ) {
    //   console.log("getStartedQuote redirect to getStartedLanding");
    //   navigate(routes("getStartedLanding"));
    // }
    // Temporarily taking the self-pricing pages offline by redirecting to landing
    console.log("getStartedQuote redirecting to getStartedLanding");
    navigate(routes("getStartedLanding"), { replace: true });
  }, [urlParams]);

  const { capacityParams } = pricingLookupTableData;

  const title = "ECL Price Quote";
  const description = "Download your personalized quote to get started";
  // Keep defaults for testing purposes
  const fullName = urlParams?.get("fullName") || "Marie Curie";
  const companyName = urlParams?.get("companyName") || "Company XYZ";
  const address =
    urlParams?.get("address") || "123 Street, San Francisco, CA, USA";
  const submitDate = urlParams?.get("submitDate") || "June 3, 2020";
  const quoteNumber =
    urlParams?.get("quoteNumber") || "mariecurie-06032020-######";
  const quoteExpirationDate =
    urlParams?.get("quoteExpirationDate") || "June 18, 2020";
  const desiredStartDate =
    urlParams?.get("desiredStartDate") || "January 15, 2021";
  let accountType = urlParams?.get("accountType") || "Startup";
  let pricingTableState = JSON.parse(urlParams?.get("data")) || {
    capacity: capacityParams.Basic.DisplayText,
    renewal: "1 year",
    licenses: "3",
    storage: "1",
    tutoring: "1",
    training: "Level 1 Open",
    level2: true,
    level3: false,
    level4: false,
    level5: false,
  };

  const refCapture = createRef();
  const refMm = createRef();
  const [busy, setBusy] = useState(false);

  /**
   * Generating pdf from html has many caveats that are nicely outlined here:
   * https://www.smashingmagazine.com/2019/06/create-pdf-web-application/
   *
   * This approach gives the flexibility of using our existing html without
   * needing a Print User Agent, and still creating a pdf of acceptable quality.
   * This approach does not rely on the browser's print to pdf feature, since
   * this was pointed out as a requirement from the sales team that customers
   * may not have this set up properly.
   *
   * Note: Do not use svg for images in this view because svgs do not translate
   * well in html2canvas. Instead, we are using png for our ecl logo here.
   */
  const onClick = () => {
    setBusy(true);

    const a4WidthMm = 210;
    const a4HeightMm = 297;
    const marginTopLeft = 15;

    const input = refCapture.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", [a4WidthMm, a4HeightMm]);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * marginTopLeft;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      const numPages = Math.ceil(pdfHeight / (a4HeightMm - 2 * marginTopLeft));
      for (let i = 0; i < numPages; i += 1) {
        if (i !== 0) {
          pdf.addPage([a4WidthMm, a4HeightMm]);
        }
        pdf.addImage(
          imgData,
          "PNG",
          marginTopLeft,
          marginTopLeft - a4HeightMm * i + 2 * marginTopLeft * i, // Adjusting for margins
          pdfWidth,
          pdfHeight
        );
        pdf.setFillColor("#FFFFFF");
        // Add margin top
        pdf.rect(0, 0, a4WidthMm, marginTopLeft, "F");
        // Add margin bottom
        pdf.rect(0, a4HeightMm - marginTopLeft, a4WidthMm, marginTopLeft, "F");
      }

      pdf.save("ECL_Price_Quote.pdf");
      setBusy(false);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <div ref={refMm} style={{ height: "1mm" }} />
      <GetStartedPageContainer
        state={{
          data: pricingTableState,
          accountType,
        }}
        step="pricing"
        showIcon={false}
        uiTheme="light"
      >
        <S.Content ref={refCapture}>
          <S.Row className="letter-head">
            <S.Col className="horizontal">
              <S.LogoImg src={eclLogoLight} />
              <S.EclAddress>
                15500 Wells Port Drive, Austin, TX 78728
              </S.EclAddress>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col className="separate">
              <div className="header">Price Quote</div>
              <S.Button
                className={cn({ busy })}
                onClick={onClick}
                data-html2canvas-ignore
              >
                {busy ? "Downloading(please wait)..." : "Download Quote"}
              </S.Button>
            </S.Col>
          </S.Row>
          <S.Row className="summary">
            <S.Col>
              <S.SubTable>
                <S.SummaryKeys>
                  <div>Prepared for</div>
                  <div>Company</div>
                  <div>Address</div>
                  <div>Date submitted</div>
                  <div>Quote number</div>
                  <div>Quote expires</div>
                  <div>Desired start date</div>
                </S.SummaryKeys>
                <S.SummaryValues>
                  <div>{fullName}</div>
                  <div>{companyName}</div>
                  <div>{address}</div>
                  <div>{submitDate}</div>
                  <div>{quoteNumber}</div>
                  <div>{quoteExpirationDate}</div>
                  <div>
                    {desiredStartDate}
                    <div className="note">
                      Please discuss your account start date with your ECL sales
                      representative
                    </div>
                  </div>
                </S.SummaryValues>
              </S.SubTable>
            </S.Col>
          </S.Row>
          <S.Row className="pricing-table">
            <S.Col>
              <GetStartedPricingTable
                step="quote"
                accountType={accountType}
                state={pricingTableState}
                uiTheme="light"
              />
            </S.Col>
          </S.Row>
          <S.Row className="tos">
            <S.Col className="fine-print final">
              All services are offered subject to ECL's standard{" "}
              <LinkToOpenInNewWindow
                className="sm hover-underline"
                href={routes("tos")}
              >
                Terms of Service (emeraldcloudlab.com/ecl-terms-of-service/)
              </LinkToOpenInNewWindow>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col className="fine-print final">
              This quote is provided for informational use only. Prices are
              subject to change.
            </S.Col>
          </S.Row>
        </S.Content>
      </GetStartedPageContainer>
    </ThemeProvider>
  );
};

export default GetStartedQuotePage;

const S = {};

S.LogoImg = styled.img`
  flex: 0 0 auto;
  width: 18.4rem;
`;

S.EclAddress = styled.div`
  color: ${({ theme }) => theme.darkGray4};
  // flex: 0 0 auto;
  font-size: 1.7rem;
  font-weight: 300;
  margin-left: 2rem;
`;

S.Button = styled.div`
  border: 1px solid ${({ theme }) => theme.lightGray1};
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.2rem 1.7rem;
  text-transform: uppercase;
  width: fit-content;
  &:hover {
    transition: all 0.3s linear;
    border: 1px solid ${({ theme }) => theme.black};
  }
  &.busy {
    text-transform: none;
    color: ${({ theme }) => theme.lightGray3};
    border-color: ${({ theme }) => theme.lightGray3a40};
    cursor: not-allowed;
    &:hover {
      border-color: ${({ theme }) => theme.lightGray3a40};
    }
  }
`;

S.Content = styled.div`
  padding: 2rem;
`;

S.Row = styled(Row)`
  margin-bottom: 2rem;
  &.letter-head,
  &.summary {
    margin-bottom: 3rem;
  }
  &.pricing-table,
  &.tos {
    margin-bottom: 1rem;
  }
`;

S.Col = styled(Col)`
  &.horizontal {
    align-items: center;
    display: flex;
  }
  &.fine-print {
    font-weight: 300;
    &.final {
      color: ${({ theme }) => theme.lightGray2};
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &.separate {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .header {
    font-size: 2.4rem;
    font-weight: 500;
  }
`;

S.SubTable = styled.div`
  display: flex;
  font-size: 1.7rem;
  letter-spacing: 0;
  line-height: 2.8rem;
`;

S.SummaryKeys = styled.div`
  color: ${({ theme }) => theme.black};
  font-weight: 500;
`;

S.SummaryValues = styled.div`
  color: ${({ theme }) => theme.black};
  font-weight: 300;
  margin-left: 4rem;
  .note {
    color: ${({ theme }) => theme.lightGray3};
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
`;
